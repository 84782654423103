import React, { useState } from "react";



const Modal = () => {
  const [showModal, setShowModal] = useState(true);

  return (
    <>
      {showModal ? (
        <>
          <div
            className="relative z-50"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg p-3 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="pt-5">
                    <div className="sm:flex sm:items-start">
                      <button
                        className="absolute top-3 right-3 px-2 rounded-full text-red-500 hover:text-[#00B3B3] bg-white"
                        id="modal-title"
                        onClick={() => setShowModal(false)}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                      <div className="w-full flex justify-center item-center flex-col ">
                        <div className="w-full flex justify-center ">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/videos-a7d6e.appspot.com/o/1717434735355_0.png?alt=media&token=91f3c750-2b4c-4602-b230-46ade9743991"
                            className="md:w-[90%] w-[70%]"
                            alt="Not Found"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Modal;
